// dev and qa
// export const REACT_APP_API_URL = "https://dev.dynamicwebsite.co.in/ab/gps/qa/api/";

// Stage
// export const REACT_APP_API_URL =
//   "https://dev.dynamicwebsite.co.in/ab/gps/stage/api/";

// Production
export const REACT_APP_API_URL =
  "https://stage.globalparkingservices.com/adminmanager/api/";

// export const REACT_APP_API_URL = "http://34.193.221.44/api/";
// export const REACT_APP_API_URL = "http://34.193.221.44/adminmanager/api/";
// export const REACT_APP_API_URL = "http://34.195.105.216/adminmanager/api/";
// export const REACT_APP_API_URL = "https://globalparkingservices.com/adminmanager/api/";
