const subPath = "";
// const subPath = "/ab/gps/web"
// const subPath = "/ab/gps/web";

export const routes = {
  home: {
    path: `${subPath}/`,
    name: "Home Page",
  },
  contactUs: {
    path: `${subPath}/contact-us`,
    name: "Contact Us",
  },
  unpaidViolation: {
    path: `${subPath}/unpaid-violation`,
    name: "Unpaid Violation",
  },
  pastViolations: {
    path: `${subPath}/past-violations`,
    name: "Past Violations",
  },
  violationDetails: {
    path: `${subPath}/violation-details`,
    name: "Violation Details",
  },
  payPal: {
    path: `${subPath}/paypal`,
    name: "Paypal Payment",
  },
  success: {
    path: `${subPath}/success`,
    name: "Payment Success",
  },
  removalInstruction: {
    path: `${subPath}/removal-instruction`,
    name: "Removal Instruction",
  },
  pageNotFound: {
    path: `${subPath}/not-found`,
    name: "Page Not Found",
  },
};
