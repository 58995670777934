import { upperFirst } from 'lodash';
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import ApiLoader from '../common/loader';
import { getPDFLink, getViolationDetailsSuccess } from "../redux/actions/violationActions";
import {routes} from "../routings";

function formatDate(string) {
    var options = { year: 'numeric', month: 'long', day: 'numeric' };
    var hours = new Date(string).getHours();
    var minutes = new Date(string).getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    return new Date(string).toLocaleDateString('en-US', options) + " | " + hours + ":" + minutes + " " + ampm;
}
class ViolationDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sendBack: false,
            display: true
        }
        
        
    }
    componentDidMount() {
        setTimeout(function() {
            this.setState({display : false})
            // alert('hello')
        }.bind(this)
        , 7200000); // <-- time for 2 hr in milliseconds is 7,200,000
    }
    sendBack = () => {
        this.props.getViolationDetailsSuccess({response:{}});
        this.setState({ sendBack: true });
    }
    render() {
        const violationDetails = (Object.keys(this.props.violationDetails).length > 0) ? this.props.violationDetails : this.props.location.state.violationDetails;
        return (
            <div>
                {this.state.sendBack &&
                    <Redirect
                        to={{
                            pathname: `${routes.pastViolations.path}`,
                            state: {
                                plateNumber: (Object.keys(this.props.violationsData).length > 0) ? this.props.violationsData : this.props.location.state.violationsData,
                                violationsData: (Object.keys(this.props.violationsData).length > 0) ? this.props.violationsData : this.props.location.state.violationsData
                            }
                        }}
                    />
                }
                {this.props.loading && <ApiLoader />}
                <section className="main_section wrong_part">
                    <div className="container">
                        <div className="row">
                        <div className="col-md-6 hidden-md hidden-lg hidden-xl">
                                <div className="main_crousel">
                                <h1 className="viol_det1">Violation Details</h1>
                                    <div id="myCarousel" className="carousel slide" data-ride="carousel">
                                        {/* <!-- Wrapper for slides --> */}
                                        <div className="carousel-inner" role="listbox">
                                            {violationDetails.images ? violationDetails.images.length > 0 ?
                                                <React.Fragment>
                                                    {violationDetails.images.map((image, key) => (
                                                        <div className={key === 0 ? "item active" : "item"} key={key}>
                                                            <img src={image} alt="GPS" />
                                                        </div>))}
                                                </React.Fragment> :
                                                <div className="item active">
                                                    <img src="assets/images/w-img1.png" alt="GPS" />
                                                </div>:
                                                <div className="item active">
                                                    <img src="assets/images/w-img1.png" alt="GPS" />
                                                </div>}
                                        </div>

                                        {violationDetails.images ? violationDetails.images.length > 0 ?
                                        <React.Fragment>
                                        <a className="left carousel-control" href="#myCarousel" role="button" data-slide="prev">
                                            <span className="glyphicon glyphicon-chevron-left" aria-hidden="true"></span>
                                            <span className="sr-only">Previous</span>
                                        </a>
                                        <a className="right carousel-control" href="#myCarousel" role="button" data-slide="next">
                                            <span className="glyphicon glyphicon-chevron-right" aria-hidden="true"></span>
                                            <span className="sr-only">Next</span>
                                        </a></React.Fragment>:"":""}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="left_part">
                                    <h1 className="viol_det">Violation Details #{violationDetails.id}</h1>
                                    <h3>Vehicle Details</h3>
                                </div>
                                <div className="boxInner">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="lp pl1">
                                            <p>LP or TAG#</p>
                                            <h5>{violationDetails.license_plate_number}</h5>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="lp pl1">
                                            <p>Make/Modal/Color</p>
                                            <h5>{upperFirst(violationDetails.make + " " + violationDetails.model + ", " + violationDetails.color)}</h5>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="lp pl1">
                                            <p>Boot#</p>
                                            <h5>{violationDetails.boot_device}</h5>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="lp pl1">
                                            <p>Location</p>
                                            <h5>{violationDetails.location}</h5>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                <div className="left_part">
                                    <h3 className="violate">Violation Details</h3>
                                </div>
                                <div className="boxInner">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="lp pl1">
                                            <p>Reason</p>
                                            <h5>{upperFirst(violationDetails.reason)}</h5>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="lp pl1">

                                        </div>
                                    </div>
                                    <div className="clearfix"></div>
                                    {/* <div className="col-md-6">
                                        <div className="lp pl1">
                                            <p>Officer Name</p>
                                            <h5>{violationDetails.officer_name}</h5>
                                        </div>
                                    </div> */}
                                    <div className="col-md-6">
                                        <div className="lp pl1">
                                            <p>Date | Time</p>
                                            <h5>{formatDate(violationDetails.date)}</h5>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                {violationDetails.unlock_code !== "" ?  
                                   
                                    this.state.display ?
                                    <div className="copy_code" >
                                       
                                        <p>Your unlock code is:</p>
                                        <h3>{violationDetails.unlock_code}</h3>
                                       
                                    </div>
                                    :
                                    null
                                : 
                                    null 
                                }
                                 <div className="bottom_buttons">
                                    <a href={violationDetails.pdf} target="_blank">
                                <button className="yellow_btn">Download Receipt</button></a>
                                    {/* <button onClick={() => this.props.getPDFLink(violationDetails.id, violationDetails.license_plate_number)} className="yellow_btn">Download Receipt</button> */}
                                    <button onClick={this.sendBack} className="default">Back</button>
                                </div>
                            </div>
                            <div className="col-md-6 hidden-xs hidden-sm">
                                <div className="main_crousel">
                                    <div id="myCarousel1" className="carousel slide" data-ride="carousel">
                                        {/* <!-- Wrapper for slides --> */}
                                        <div className="carousel-inner" role="listbox">
                                            {violationDetails.images ? violationDetails.images.length > 0 ?
                                                <React.Fragment>
                                                    {violationDetails.images.map((image, key) => (
                                                        <div className={key === 0 ? "item active" : "item"} key={key}>
                                                            <img src={image} alt="GPS" />
                                                        </div>))}
                                                </React.Fragment> :
                                                <div className="item active">
                                                    <img src="assets/images/w-img1.png" alt="GPS" />
                                                </div>:
                                                <div className="item active">
                                                    <img src="assets/images/w-img1.png" alt="GPS" />
                                                </div>}
                                        </div>

                                        {violationDetails.images ? violationDetails.images.length > 0 ?
                                        <React.Fragment>
                                        <a className="left carousel-control" href="#myCarousel1" role="button" data-slide="prev">
                                            <span className="glyphicon glyphicon-chevron-left" aria-hidden="true"></span>
                                            <span className="sr-only">Previous</span>
                                        </a>
                                        <a className="right carousel-control" href="#myCarousel1" role="button" data-slide="next">
                                            <span className="glyphicon glyphicon-chevron-right" aria-hidden="true"></span>
                                            <span className="sr-only">Next</span>
                                        </a></React.Fragment> : "" : ""}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    loading: state.violation.loading,
    violationDetails: state.violation.violationDetails,
    violationsData: state.violation.violationsData
});

const dispatchers = {
    getPDFLink,
    getViolationDetailsSuccess
};

export default connect(mapStateToProps, dispatchers)(ViolationDetails);