import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getViolatiosByPlateNumber,
  getUnlockCodeByPayment,
  getFirmwareVersion,
  postPaymentAuthorizeDotNet,
} from "../redux/actions/violationActions";
import PaypalPayment from "./paypal";
import PaymentSuccess from "./paymentSuccess";
import PaymentFail from "./failure";
import { Link } from "react-router-dom";
import ApiLoader from "../common/loader";
import { routes } from "../routings";
import AuthorizePayment from "./AuthorizePayment";
import { toast } from "react-toastify";
import axios from "axios";
import { REACT_APP_API_URL } from "../common/config";
import CryptoJS from "crypto-js";

function formatDate(string) {
  var options = { year: "numeric", month: "long", day: "numeric" };
  var hours = new Date(string).getHours();
  var minutes = new Date(string).getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  return (
    new Date(string).toLocaleDateString("en-US", options) +
    " | " +
    hours +
    ":" +
    minutes +
    " " +
    ampm
  );
}

class UnpaidViolation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isChecked: false,
      showError: false,
      showPaymentModal: false,
      sendTo: "",
      isPaymentDone: false,
      signatureChecked: false,
      emailAddress: "",
      signatureText: "",
      showSignatureError: false,
      emailError: false,
      isvalid: false,
      message: "",
      firmwareError: false,
      paymentLoader: false,
      propertyId: "",
      bootRemovalVideoLink: "",
      pdf: "",
      unlockCode: "",
      key: "",
      iv: "",
    };
    this.showPaymentForm = this.showPaymentForm.bind(this);
  }

  componentDidMount() {
    if (this.props.location.state) {
      axios
        .get(REACT_APP_API_URL + "web/front-admin-public-data")
        .then((res) => {
          this.setState({ key: res?.data?.data?.ed_secret_key });
          this.setState({ iv: res?.data?.data?.ed_secret_vi });
        });
      // console.log("plate Number", this.props.location.state.plateNumber);
    } else {
      window.location.href = `${routes.home.path}`;
    }
  }

  emailValidation() {
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return !(
      !this.state.emailAddress || regex.test(this.state.emailAddress) === false
    );
  }

  async showPaymentForm() {
    console.log("before");
    const res = await this.props.getFirmwareVersion(
      this.props.location.state.violationsData.response.id
    );
    // console.log('firmware version',this.props.firmwareVersion)
    if (this.props.firmwareVersion === true) {
      console.log("After");
      const isEmailValid = this.emailValidation();
      if (this.state.isChecked) {
        // alert('hello')
        this.setState({ showPaymentModal: true });
      } else {
        this.setState({ showError: true, showPaymentModal: false });
      }
      if (this.state.signatureChecked && this.state.signatureText.length > 0) {
        this.setState({ showSignatureError: false });
      } else {
        this.setState({ showSignatureError: true, showPaymentModal: false });
      }
      if (this.state.emailAddress.length > 0 && isEmailValid) {
        this.setState({ emailError: false });
      } else {
        this.setState({ emailError: true, showPaymentModal: false });
      }
      if (this.state.isChecked && this.state.signatureText.length > 0) {
        this.setState({ showSignatureError: false });
      } else {
        this.setState({ showSignatureError: true, showPaymentModal: false });
      }
      if (
        this.state.isChecked &&
        this.state.signatureText.length > 0 &&
        this.state.emailAddress.length > 0 &&
        isEmailValid &&
        this.state.signatureChecked &&
        this.state.signatureText.length > 0
      ) {
        this.setState({ showPaymentModal: true });
      }
    } else {
      this.setState({ firmwareError: true });
    }
  }

  showFailure = () => {
    this.setState({ sendTo: "failure", isPaymentDone: true });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (Object.keys(nextProps?.paymentResponse).length > 0) {
      if (nextProps?.paymentResponse?.response?.unlockCode === "") {
        return {
          sendTo: "failure",
          isPaymentDone: true,
        };
      } else if (nextProps?.paymentResponse?.response?.unlockCode) {
        return {
          sendTo: "success",
          isPaymentDone: true,
        };
      } else {
        return {
          sendTo: "failure",
          isPaymentDone: true,
        };
      }
    } else {
      return null;
    }
  }

  onSuccessTransaction = (txnId, details) => {
    const violation = this.props.location.state.violationsData.response;
    var order_details = {
      txnId: txnId,
      txnAmount: details.purchase_units[0].amount.value,
      paymentStatus: details.status,
      officerName: violation.officer_name,
      licensePlateNumber: violation.license_plate_number,
      bootDevice: violation.boot_device,
      id: violation.id,
      signature: this.state.signatureText,
      user_email: this.state.emailAddress,
    };
    this.props.getUnlockCodeByPayment(order_details);
  };

  GetCardType = (cc) => {
    let amex = new RegExp("^3[47]");
    let visa = new RegExp("^4[0-9]{12}(?:[0-9]{3})?$");
    let cup1 = new RegExp("^62[0-9]{14}[0-9]*$");
    let cup2 = new RegExp("^81[0-9]{14}[0-9]*$");

    let mastercard = new RegExp("^5[1-5][0-9]{14}$");
    let mastercard2 = new RegExp("^2[2-7][0-9]{14}$");

    let disco1 = new RegExp("^6011[0-9]{12}[0-9]*$");
    let disco2 = new RegExp("^62[24568][0-9]{13}[0-9]*$");
    let disco3 = new RegExp("^6[45][0-9]{14}[0-9]*$");

    let diners = new RegExp("^3[0689][0-9]{12}[0-9]*$");
    let jcb = new RegExp("^35[0-9]{14}[0-9]*$");

    if (visa.test(cc)) {
      return "VISA";
    }
    if (amex.test(cc)) {
      return "AMEX";
    }
    if (mastercard.test(cc) || mastercard2.test(cc)) {
      return "MASTERCARD";
    }
    if (disco1.test(cc) || disco2.test(cc) || disco3.test(cc)) {
      return "DISCOVER";
    }
    if (diners.test(cc)) {
      return "DINERS";
    }
    if (jcb.test(cc)) {
      return "JCB";
    }
    if (cup1.test(cc) || cup2.test(cc)) {
      return "CHINA_UNION_PAY";
    }
    return undefined;
  };

  getEncryptedData = (item, key, iv) => {
    var Sha256 = CryptoJS.SHA256;
    var Hex = CryptoJS.enc.Hex;
    var Utf8 = CryptoJS.enc.Utf8;
    var Base64 = CryptoJS.enc.Base64;
    var AES = CryptoJS.AES;

    var secret_key = key;
    var secret_iv = iv;

    var key = Sha256(secret_key).toString(Hex).substr(0, 32); // Use the first 32 bytes (see 2.)
    var iv = Sha256(secret_iv).toString(Hex).substr(0, 16);

    // Encryption
    var output = AES.encrypt(item, Utf8.parse(key), {
      iv: Utf8.parse(iv),
    }).toString(); // First Base64 encoding, by default (see 1.)
    var output2ndB64 = Utf8.parse(output).toString(Base64); // Second Base64 encoding (see 1.)
    return output2ndB64;
  };

  //  handle submit for payment
  handleSubmit = async (values) => {
    this.setState({ paymentLoader: true });
    const violation = this.props.location.state.violationsData.response;
    // console.log("values", values);
    const data = {
      violation_id: violation.id,
      licensePlateNumber: violation.license_plate_number,
      signature: this.state.signatureText,
      cardNumber: this.getEncryptedData(
        values.cardNumber,
        this.state.key,
        this.state.iv
      ),
      expiration_year: this.getEncryptedData(
        values.expirationYear,
        this.state.key,
        this.state.iv
      ),
      expiration_month: this.getEncryptedData(
        values.expirationMonth,
        this.state.key,
        this.state.iv
      ),
      cvv: this.getEncryptedData(values.cvv, this.state.key, this.state.iv),
      amount: violation.amount,
      total_amount: "",
      hold_amount: "",
      name_on_card: values.nameOnCard,
      email: this.state.emailAddress,
    };

    // const res = postPaymentAuthorizeDotNet(data);
    // console.log("res", res);
    await postPaymentAuthorizeDotNet(data)
      .then((response) => {
        console.log("=====response", response);
        if (response.status === 200) {
          console.log("response", response.data);
          toast.success(response?.data?.message);
          this.setState({
            sendTo: "success",
            isPaymentDone: true,
            propertyId: response?.data?.property_id,
            bootRemovalVideoLink: response?.data?.boot_removal_video_link,
            pdf: response?.data?.pdf,
            unlockCode: response?.data?.unlockCode,
          });
          this.setState({ paymentLoader: false });
        }
      })
      .catch((error) => {
        console.log("error", error.response);
        toast.error(error.response.data.message);
        this.setState({ paymentLoader: false });
      });
    // console.log("this.state", this.state);
  };

  render() {
    let { violation, paymentResponse, loading } = this.props;

    let {
      isChecked,
      showError,
      emailError,
      showSignatureError,
      showPaymentModal,
      sendTo,
      isPaymentDone,
      signatureChecked,
      emailAddress,
      signatureText,
      paymentLoader,
      propertyId,
      bootRemovalVideoLink,
      pdf,
      unlockCode,
    } = this.state;
    if (this.props.location.state) {
      violation = this.props.location.state.violationsData.response;
    } else {
      if (Object.keys(violation).length > 0) {
        violation = violation.response;
      }
    }
    if (isChecked === true) {
      showError = false;
    }
    return (
      <div>
        {paymentLoader && <ApiLoader />}
        {loading && <ApiLoader />}
        {/* {console.log("state", this.state)} */}
        {isPaymentDone === true && sendTo === "success" ? (
          // <PaymentSuccess
          //   propertyId={paymentResponse.response.property_id}
          //   bootRemovalVideoLink={
          //     paymentResponse.response.boot_removal_video_link
          //   }
          //   unlockCode={paymentResponse.response.unlockCode}
          //   license_plate_number={violation.license_plate_number}
          //   id={violation.id}
          //   pdf={violation.pdf}
          // />
          <PaymentSuccess
            propertyId={propertyId}
            bootRemovalVideoLink={bootRemovalVideoLink}
            unlockCode={unlockCode}
            license_plate_number={violation.license_plate_number}
            id={violation.id}
            pdf={pdf}
          />
        ) : isPaymentDone === true && sendTo === "failure" ? (
          <PaymentFail />
        ) : (
          ""
        )}
        {isPaymentDone === false && (
          <React.Fragment>
            <section className="main_section wrong_part wrong_part_past">
              <div className="container">
                <div className="row">
                  <div className="col-md-6 hidden-md hidden-lg hidden-xl">
                    <div className="main_crousel">
                      <h1 className="viol_det1">Violation Details</h1>
                      <div
                        id="myCarousel"
                        className="carousel slide"
                        data-ride="carousel"
                      >
                        {/* <!-- Wrapper for slides --> */}
                        <div className="carousel-inner" role="listbox">
                          {violation.images ? (
                            <React.Fragment>
                              {violation.images.map((image, key) => (
                                <div
                                  className={key === 0 ? "item active" : "item"}
                                  key={key}
                                >
                                  <img src={image} alt="GPS" />
                                </div>
                              ))}
                            </React.Fragment>
                          ) : (
                            <div className="item active">
                              <img src="assets/images/w-img1.png" alt="GPS" />
                            </div>
                          )}
                        </div>

                        {/* <!-- Left and right controls --> */}
                        {violation.images ? (
                          violation.images.length > 0 ? (
                            <React.Fragment>
                              <a
                                className="left carousel-control"
                                href="#myCarousel"
                                role="button"
                                data-slide="prev"
                              >
                                <span
                                  className="glyphicon glyphicon-chevron-left"
                                  aria-hidden="true"
                                ></span>
                                <span className="sr-only">Previous</span>
                              </a>
                              <a
                                className="right carousel-control"
                                href="#myCarousel"
                                role="button"
                                data-slide="next"
                              >
                                <span
                                  className="glyphicon glyphicon-chevron-right"
                                  aria-hidden="true"
                                ></span>
                                <span className="sr-only">Next</span>
                              </a>
                            </React.Fragment>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="left_part">
                      <h1 className="viol_det">
                        Violation Details #{violation.id}
                      </h1>
                      <h3>Vehicle Details</h3>
                    </div>
                    <div className="boxInner">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="lp">
                            <p>LP or TAG#</p>
                            <h5>{violation.license_plate_number}</h5>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="lp">
                            <p>Make/Modal/Color</p>
                            <h5 className="text-capitalize">
                              {violation.make +
                                " " +
                                violation.model +
                                "," +
                                violation.color}
                            </h5>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="lp">
                            <p>Boot#</p>
                            <h5>
                              {violation.boot_serial_number
                                ? violation.boot_serial_number
                                : "-"}
                            </h5>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="lp">
                            <p>Location</p>
                            <h5>{violation.location}</h5>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="lp">
                            <p>Address</p>
                            <h5>{violation.address}</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="left_part">
                      <h3 className="violate">Violation Details</h3>
                    </div>
                    <div className="boxInner">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="lp">
                            <p>Reason</p>
                            <h5>{violation.reason}</h5>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="lp"></div>
                        </div>
                        <div className="clearfix"></div>
                        {/* <div className="col-md-6">
                                        <div className="lp">
                                            <p>Officer Name</p>
                                            <h5>{violation.officer_name}</h5>
                                        </div>
                                    </div> */}
                        <div className="col-md-6">
                          <div className="lp">
                            <p>Date | Time</p>
                            <h5>{formatDate(violation.date)}</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="lp email_text">
                          <p>Email</p>
                          <input
                            value={this.state.emailAddress}
                            placeholder="Enter Email"
                            type="email"
                            id="email"
                            name="email"
                            onChange={(e) =>
                              this.setState({ emailAddress: e.target.value })
                            }
                          />
                        </div>
                        {emailError && (
                          <p
                            style={{ fontWeight: "bold" }}
                            className="text-danger"
                          >
                            Please enter valid email
                          </p>
                        )}
                      </div>
                      <div className="col-md-12">
                        <div className="checkeed">
                          <input
                            type="checkbox"
                            id="signature"
                            checked={signatureChecked}
                            onChange={() =>
                              this.setState({
                                signatureChecked: !signatureChecked,
                              })
                            }
                          />
                          <label htmlFor="signature">
                            <span></span> Your Digital signature accepts the
                            terms of this agreement. &nbsp;{" "}
                            <a
                              href="#"
                              data-toggle="modal"
                              data-target="#myModal2"
                            >
                              Read more
                            </a>
                          </label>
                        </div>
                      </div>

                      {signatureChecked && (
                        <div className="col-md-12 email_text">
                          <input
                            placeholder="Enter Signature"
                            type="text"
                            id="text"
                            name="text"
                            onChange={(e) =>
                              this.setState({ signatureText: e.target.value })
                            }
                          />
                        </div>
                      )}
                    </div>
                    {showSignatureError && (
                      <p style={{ fontWeight: "bold" }} className="text-danger">
                        You must agree to the Digital Signature and provide
                        valid signature.
                      </p>
                    )}
                    <div className="checkeed">
                      <input
                        type="checkbox"
                        id="one"
                        checked={isChecked}
                        onChange={() =>
                          this.setState({ isChecked: !isChecked })
                        }
                      />
                      <label htmlFor="one">
                        <span></span> I Agree
                      </label>
                      <p className="if_yu">
                        If you illegally remove the vehicle immobilization
                        device without payment, we will provide all of your
                        information directly to your local police department to
                        pursue criminal charges.{" "}
                        <a href="#" data-toggle="modal" data-target="#myModal1">
                          Read more
                        </a>
                      </p>

                      {showError && (
                        <p
                          style={{ fontWeight: "bold" }}
                          className="text-danger"
                        >
                          You must agree to the terms and conditions before you
                          can proceed.
                        </p>
                      )}
                    </div>
                    <label htmlFor="one">
                      <span></span> Note
                    </label>
                    <label>
                      Boot Damage Deposit ${violation.hold_amount}. <br />
                      Once we have verified the boot is not
                      damaged/destroyed/missing. We will refund your Deposit.
                    </label>

                    {/* Once we verify the boot is not
                        damaged/destroyed/missing. We will refund your hold
                        amount ${violation.hold_amount}. */}
                    {this.state.firmwareError && (
                      <p style={{ fontWeight: "bold" }} className="text-danger">
                        Boot device is not activated. Please contact
                        Administrator.
                      </p>
                    )}
                    <div className="bottom_buttons">
                      <button
                        onClick={this.showPaymentForm}
                        className="yellow_btn"
                      >
                        Unlock Boot for $
                        {Number(violation.amount) +
                          Number(violation.hold_amount)}
                      </button>
                      <a href={routes.home.path} className="default">
                        Back
                      </a>
                    </div>
                  </div>
                  <div className="col-md-6 hidden-xs hidden-sm">
                    <div className="main_crousel">
                      <div
                        id="myCarousel1"
                        className="carousel slide"
                        data-ride="carousel"
                      >
                        {/* <!-- Wrapper for slides --> */}
                        <div className="carousel-inner" role="listbox">
                          {violation.images ? (
                            violation.images.length > 0 ? (
                              <React.Fragment>
                                {violation.images.map((image, key) => (
                                  <div
                                    className={
                                      key === 0 ? "item active" : "item"
                                    }
                                    key={key}
                                  >
                                    <img src={image} alt="GPS" />
                                  </div>
                                ))}
                              </React.Fragment>
                            ) : (
                              <div className="item active">
                                <img src="assets/images/w-img1.png" alt="GPS" />
                              </div>
                            )
                          ) : (
                            <div className="item active">
                              <img src="assets/images/w-img1.png" alt="GPS" />
                            </div>
                          )}
                        </div>

                        {/* <!-- Left and right controls --> */}
                        {violation.images ? (
                          violation.images.length > 0 ? (
                            <React.Fragment>
                              <a
                                className="left carousel-control"
                                href="#myCarousel1"
                                role="button"
                                data-slide="prev"
                              >
                                <span
                                  className="glyphicon glyphicon-chevron-left"
                                  aria-hidden="true"
                                ></span>
                                <span className="sr-only">Previous</span>
                              </a>
                              <a
                                className="right carousel-control"
                                href="#myCarousel1"
                                role="button"
                                data-slide="next"
                              >
                                <span
                                  className="glyphicon glyphicon-chevron-right"
                                  aria-hidden="true"
                                ></span>
                                <span className="sr-only">Next</span>
                              </a>
                            </React.Fragment>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div className="modal fade" id="myModal1">
              <div className="modal-dialog">
                <div className="modal-content">
                  {/* <!-- Modal Header --> */}
                  <div className="modal-header">
                    <h4 className="modal-title">Terms and conditions:</h4>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                    >
                      ×
                    </button>
                  </div>

                  {/* <!-- Modal body --> */}
                  <div className="modal-body">
                    <p>
                      In consideration of being permitted to self release a
                      vehicle immobilization device, the accepting party
                      releases and forever discharges the enforcement company
                      (G.P.S.), the companies spouse, heirs, executors,
                      administrators, legal representatives and assigns from all
                      manner of actions, causes of action, debts, accounts,
                      bonds, contracts, claims and demands for or by reason of
                      any injury to person or property, including injury
                      resulting in the death of the participant which has been
                      or may be sustained as a consequence of the accepting
                      parties participation in the activity described as self
                      releasing a vehicle immobilization device, and not
                      withstanding that such damage, loss or injury may have
                      been caused solely or partly by the negligence of the
                      enforcement company. This waiver will be construed in
                      accordance with and governed by the laws of the state of
                      colorado.
                    </p>

                    <p>
                      If you illegally remove the vehicle immobilization device
                      without payment, we will provide all of your information
                      directly to your local police department to pursue
                      criminal charges. Please note that your vehicles’
                      make/model/vin/color/license plate # has been logged,
                      photographed, and reported. When legal matters are
                      resolved, and you are found at fault, you will owe for the
                      destruction of property, the removal fee, and any court
                      costs that will be accrued in the process.
                    </p>
                  </div>

                  {/* <!-- Modal footer --> */}
                  <div className="modal-footer">
                    <div className="checkeed new_modalcheck">
                      <input
                        type="checkbox"
                        id="two"
                        checked={isChecked}
                        onChange={() =>
                          this.setState({ isChecked: !isChecked })
                        }
                      />
                      <label htmlFor="two">
                        <span></span> I Agree
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="modal fade" id="myModal2">
              <div className="modal-dialog">
                <div className="modal-content">
                  {/* <!-- Modal Header --> */}
                  <div className="modal-header">
                    <h4 className="modal-title">
                      Electronic Signature Agreement:
                    </h4>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                    >
                      ×
                    </button>
                  </div>

                  {/* <!-- Modal body --> */}
                  <div className="modal-body">
                    <p>
                      By selecting the "I Accept" button, you are signing this
                      Agreement electronically. You agree your electronic
                      signature is the legal equivalent of your manual signature
                      on this Agreement. By selecting "I Accept" you consent to
                      be legally bound by this Agreement's terms and conditions.
                      You further agree that your use of a keypad, mouse or
                      other device to select an item, button, icon or similar
                      act/action, or to otherwise provide Global Parking
                      Services LLC. or in accessing or making any ion regarding
                      any agreement, acknowledgement, consent terms, disclosures
                      or conditions constitutes your signature (hereafter
                      referred to as "E-Signature"), acceptance and agreement as
                      if actually signed by you in writing. You also agree that
                      no certification authority or other third-party
                      verification is necessary to validate your E-Signature and
                      that the lack of such certification or third party
                      verification will not in any way affect the enforceability
                      of your E-Signature or any resulting contract between you
                      and Global Parking Services LLC. You also represent that
                      you are authorized to enter into this Agreement for all
                      persons who own or are authorized to access any of your
                      accounts and that such persons will be bound by the terms
                      of this Agreement. You further agree that each use of your
                      E-Signature in obtaining a Global Parking Services LLC.
                      online service constitutes your agreement to be bound by
                      the terms and conditions of the Global Parking Services
                      LLC.{" "}
                    </p>
                  </div>

                  {/* <!-- Modal footer --> */}
                  <div className="modal-footer">
                    <div className="checkeed new_modalcheck">
                      <input
                        type="checkbox"
                        id="three"
                        checked={signatureChecked}
                        onChange={() =>
                          this.setState({ signatureChecked: !signatureChecked })
                        }
                      />
                      <label htmlFor="three">
                        <span></span> I Agree
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              style={{
                opacity: showPaymentModal ? "1" : "0",
                display: showPaymentModal ? "block" : "none",
              }}
              className={
                showPaymentModal
                  ? "modal fade show paymentModalMain"
                  : "modal fade"
              }
              id="paymentFormModal"
              tabIndex="-1"
              role="dialog"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h3 className="modal-title">Payment</h3>
                    <button
                      type="button"
                      className="close"
                      onClick={() => this.setState({ showPaymentModal: false })}
                      data-dismiss="modal"
                    >
                      ×
                    </button>
                  </div>

                  <div className="modal-body">
                    {/* <PaypalPayment
                      amount={violation.amount}
                      onSuccess={this.onSuccessTransaction}
                      onFailure={this.showFailure}
                    /> */}
                    <AuthorizePayment
                      amount={violation.amount}
                      handleSubmit={this.handleSubmit}
                      holdAmount={violation.hold_amount}
                    />
                    {/* {console.log("violation", violation)} */}
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  violation: state.violation.violationsData,
  // paymentResponse: state.violation.paymentResponse,
  loading: state.violation.loading,
  firmwareVersion: state.violation.firmwareVersion.success,
  paymentResponse: state.violation.authorizeDotNet,
});

const dispatchers = {
  getViolatiosByPlateNumber,
  getUnlockCodeByPayment,
  getFirmwareVersion,
  // postPaymentAuthorizeDotNet,
};

export default connect(mapStateToProps, dispatchers)(UnpaidViolation);
